<template>
  <!-- 核心技术 -->
  <div class="technology">
    <ul>
      <li v-for="item in data">
        <img :src="require(`@/assets/images/${item.img}.png`)" alt="" />
        <h3>{{ item.title }}</h3>
        <p v-html="item.content"></p>
      </li>
    </ul>
  </div>
</template>

<script>
import { coreTechnology } from "@/components/data/index.js";
export default {
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    this.data = coreTechnology;
  },
};
</script>

<style lang="scss" scoped>
.technology {
  ul {
    padding: 0;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    li {
      width: 384px;
      height: 245px;
      background: url("../../../assets/images/technology-item-bg.png") no-repeat;
      background-size: cover;
      text-align: center;
      img {
        width: 80px;
        padding-top: 22px;
      }
      h3 {
        margin: 10px 0 8px;
        font-size: 18px;
        font-weight: 500;
        color: #000000;
      }
      p {
        font-weight: 400;
        color: #808080;
        font-size: 14px;
        margin: 0;
      }
    }
  }
}
</style>